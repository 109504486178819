import { ProfileBalanceSnapshotUploaded } from "../extensions/events/profile.events";
import { IProfile, IProfileLoan, IProfileInvestment, ITenant, COOPAAS } from "../extensions/types";

var ibcoop = require("../../resources/coopaas.ibcoop.setup.october.2020.json");

const STORE = { ibcoop };

function refreshSnapshotTotals(obj: IProfile, mode: 'Total' | 'AvailableTotal' | 'Both' = 'Both') {
    if (mode == 'Total' || mode == 'Both')
        obj.wallet.totalWithSnapshot = obj.wallet.total + obj.savings.total;

    if (mode == 'AvailableTotal' || mode == 'Both')
        obj.wallet.avaialbleTotalWithSnapshot = obj.wallet.availableTotal + obj.savings.total;
}

export default {
    Init: () => {
        var obj: Partial<IProfile> = {
            meta: {},
            roles: [],
            savings: {
                deposits: {},
                deductionAmount: 0,
                total: 0,
                activeTotalByMonth: 0,
                totalByMonth: {}
            },
            investments: {
                items: [],
                requests: [],
                total: 0
            },
            loans: {
                items: [],
                itemsByMonth: {},
                requests: [],
                total: 0
            },
            balanceSnapshots: {},
            wallet: {
                items: [],
                withdrawalRequests: [],
                total: 0,
                availableTotal: 0,
                totalWithSnapshot: 0,
                availableTotalWithSnapshot: 0,
                locked: false,
                lockedReason: 'Not set.'
            }
        } as any;
        return obj;
    },

    ProfileRegistered: (state: IProfile, { payload: { tenantId, staffId, fullname, reference, requiresValidation } }) => {
        var obj: IProfile = { ...state, tenantId, staffId, fullname, reference, requiresValidation };
        return obj;
    },

    ProfileValidated: (state: IProfile, { payload: { actorId } }) => {
        var obj: IProfile = { ...state, validated: true };
        return obj;
    },

    ProfileActivated: async (state: IProfile, { payload: { phone, telegramId } }, jwt) => {
        var { results, when } = STORE[state.tenantId];

        var tenant = COOPAAS.tenants.find(t => t.code == state.tenantId);

        if (tenant.administrators && tenant.administrators.indexOf(state.staffId) !== -1)
            state.roles.push("Administrators"); // Had to use 

        var record = null;
        var loans = [];
        var investments = [];
        if (results) {
            record = results.find(r => r.staffId == state.staffId);
            if (record) {
                loans = record.loans.filter(l => l.active);
                investments = record.investments.filter(l => l.active)
            }
        }
        var obj: IProfile = {
            ...state,
            savings: {
                ...state.savings,
                deposits: {},
                deductionAmount: record && record.deductionAmount != "-" ? parseFloat(record.deductionAmount) : 0,
                total: record ? parseFloat(record.totalSavings) : 0
            },
            activation: {
                phone,
                telegramId
            },
            ...(record ? {
                loans: {
                    requests: [],
                    ...state.loans,
                    items: [
                        ...loans.map<IProfileLoan>((l, index) => ({
                            id: `Loan-${index}`,
                            amount: parseFloat(l.balance),
                            durationInMonths: parseInt(l.durationInMonths),
                            type: `${l.code} - ${l.name}`,
                            when: parseFloat(when),
                            status: "Active",
                            repayments: [],
                            balance: parseFloat(l.balance),
                            batchReference: when
                        }))
                    ],
                    total: loans.reduce<number>((c, i) => c + parseFloat(i.balance), 0)
                },
                investments: {
                    items: [
                        ...investments.map<IProfileInvestment>((l, index) => ({
                            id: `Investment-${index}`,
                            amount: parseFloat(l.balance),
                            durationInMonths: parseInt(l.durationInMonths),
                            name: `${l.code} - ${l.name}`,
                            type: l.code,
                            when: parseFloat(when),
                            source: "Cash"
                        }))
                    ],
                    requests: [],
                    total: investments.reduce<number>((c, i) => c + parseFloat(i.balance), 0)
                },
                wallet: {
                    ...state.wallet,
                    items: [
                        ...state.wallet.items,
                        {
                            direction: "in",
                            amount: parseFloat(record.totalSavings),
                            source: `Savings brought forward from previous system #bfprevious (cash).`,
                            when: parseFloat(when)
                        }
                    ],
                    total: state.wallet.total,
                    availableTotal: state.wallet.availableTotal
                }
            } : {}),
        };

        refreshSnapshotTotals(obj);

        return obj;
    },

    ProfileMetaSet: (state: IProfile, { payload: { key, value } }) => {
        var roles = state.roles;
        if (key == "role")
            roles = value.split(',');
        var obj: IProfile = { ...state, meta: { ...state.meta, [key]: value }, roles };
        return obj;
    },

    ProfileMetaUnset: (state: IProfile, { payload: { key } }) => {
        var { meta } = state;
        delete meta[key];
        var obj: IProfile = { ...state, meta: { ...meta } };
        return obj;
    },

    ProfileBalanceSnapshotUploaded: (state: IProfile, { aggregateId, timestamp, payload: { snapshotType, items, month, year } }) => {
        state.balanceSnapshots[`${snapshotType}-${year}-${month}`] = true;

        if (snapshotType == "Savings") {
            var balance = items[0].balance;

            var rv: IProfile = {
                ...state,
                savings: {
                    ...state.savings,
                    total: balance
                },
                wallet: {
                    ...state.wallet,
                    avaialbleTotalWithSnapshot: state.wallet.availableTotal + balance,
                    totalWithSnapshot: state.wallet.total + balance
                }
            };

            if (rv.savings.totalByMonth)
                rv.savings.totalByMonth[`${year}${month}`] = balance;

            // Sort snapshots by date
            let keys = Object.keys(rv.savings.totalByMonth).map(k => parseInt(k));
            let keysSorted = keys.sort((a, b) => b - a);

            // Use the latest (by date) as the active loan;
            rv.savings.activeTotalByMonth = rv.savings.totalByMonth[`${keysSorted[0]}`];

            return rv;
        }

        if (snapshotType == "Loans") {
            var loans = items.map(({ id, balance, name }) => ({
                code: id,
                name,
                balance
            }));

            var rv: IProfile = {
                ...state,
                loans: {
                    ...state.loans,
                    items: loans.map((l, index) => ({
                        id: `Loan-${index}`,
                        amount: parseFloat(l.balance),
                        durationInMonths: 0,
                        type: l.code,
                        name: l.name,
                        when: parseFloat(timestamp),
                        status: "Active",
                        repayments: [],
                        balance: parseFloat(l.balance),
                        batchReference: timestamp
                    })),
                    total: loans.reduce((s, c, i) => s + c.balance, 0)
                }
            };

            // Set the loans by date
            if (rv.loans.itemsByMonth)
                rv.loans.itemsByMonth[`${year}${month}`] = rv.loans.items;

            // Sort snapshots by date
            let keys = Object.keys(rv.loans.itemsByMonth).map(k => parseInt(k));
            let keysSorted = keys.sort((a, b) => b - a);

            // Use the latest (by date) as the active loan;
            rv.loans.activeItemsByMonth = rv.loans.itemsByMonth[`${keysSorted[0]}`];

            return rv;
        }
    },

    SavingsFunded: (state: IProfile, { timestamp, payload: { source, amount, actorId, reference } }) => {
        var tenant = COOPAAS.tenants.find(t => t.code == state.tenantId);
        var obj: IProfile = {
            ...state,
            savings: {
                ...state.savings,
                deposits: {
                    ...state.savings.deposits,
                    ...(tenant.enableBalanceSnapshot ? {} : { [reference]: { source, amount, actorId } })
                },
                total: state.savings.total + (tenant.enableBalanceSnapshot ? 0 : amount)
            },
            wallet: {
                ...state.wallet,
                items: [
                    ...state.wallet.items,
                    ...(
                        tenant.enableBalanceSnapshot ? [] as any : [{
                            direction: "in",
                            amount,
                            source: `Savings funded #${reference} (${source}).`,
                            when: timestamp
                        }]
                    )
                ],
                total: state.wallet.total + (tenant.enableBalanceSnapshot ? 0 : amount),
                availableTotal: state.wallet.availableTotal + (tenant.enableBalanceSnapshot ? 0 : amount)
            }
        };
        return obj;
    },

    SavingsDeductionReviewed: (state: IProfile, { payload: { amount, actorId, reference } }) => {
        var obj: IProfile = {
            ...state,
            savings: {
                ...state.savings,
                deductionAmount: amount
            }
        };
        return obj;
    },

    SavingsDeductionAcknowledged: (state: IProfile, { payload: { amount, actorId, reference, year, month } }) => {
        var obj: IProfile = {
            ...state,
            savings: {
                ...state.savings,
                deposits: {
                    ...state.savings.deposits,
                    [`${reference}`]: {
                        source: `SalaryDeduction-${month}-${year}`,
                        actorId,
                        amount
                    }
                }
            }
        }
    },

    InvestmentCreated: (state: IProfile, { timestamp, payload: { id, amount, name, source, durationInMonths } }) => {
        var obj: IProfile = {
            ...state,
            investments: {
                ...state.investments,
                items: [
                    ...state.investments.items,
                    { id, type: durationInMonths > 12 ? 'LI' : 'SI', amount, name, durationInMonths, source: source ?? "Cash", when: timestamp }
                ],
                total: state.investments.total + amount
            }
        };
        refreshSnapshotTotals(obj, 'Total');

        if (source == "Wallet") {
            obj = {
                ...obj,
                wallet: {
                    ...state.wallet,
                    items: [
                        ...state.wallet.items,
                        {
                            direction: "out",
                            amount,
                            source: `Funded an investment with reference #${id} (${name}).`,
                            when: timestamp
                        }
                    ],
                    total: state.wallet.total - amount,
                    availableTotal: state.wallet.availableTotal - amount
                }
            };
            refreshSnapshotTotals(obj);
        }

        return obj;
    },

    InvestmentProfileRequested: (state: IProfile, { timestamp, payload: { id, amount, name, durationInMonths, tellerNumber } }) => {
        var obj: IProfile = {
            ...state,
            investments: {
                ...state.investments,
                requests: [
                    ...state.investments.requests,
                    { id, type: durationInMonths > 12 ? 'LI' : 'SI', amount, name, tellerNumber, source: "Cash", durationInMonths, status: "Pending", when: timestamp }
                ]
            }
        };
        return obj;
    },

    InvestmentProfileRequestApproved: (state: IProfile, { timestamp, payload: { id, actualAmount, actualTellerNumber, actorId } }) => {
        var { source, amount, name, durationInMonths } = state.investments.requests.find(r => r.id == id);
        var obj: IProfile = {
            ...state,
            investments: {
                ...state.investments,
                items: [
                    ...state.investments.items,
                    { id, type: durationInMonths > 12 ? 'LI' : 'SI', amount: actualAmount ?? amount, name, durationInMonths, source, when: timestamp }
                ],
                requests: [
                    ...state.investments.requests.filter(i => i.id !== id)
                ],
                total: state.investments.total + (actualAmount ?? amount)
            }
        }
        return obj;
    },

    InvestmentProfileRequestRejected: (state: IProfile, { payload: { id, reason } }) => {
        var request = state.investments.requests.find(i => i.id == id);

        request.status = "Rejected";
        request.rejectionReason = reason;

        var obj: IProfile = {
            ...state,
            investments: {
                ...state.investments
            }
        }
        return obj;
    },

    InvestmentClosed: (state: IProfile, { timestamp, payload: { id } }) => {
        var investment = state.investments.items.find(i => i.id == id);
        var obj: IProfile = {
            ...state,
            investments: {
                ...state.investments,
                items: [
                    ...state.investments.items.filter(i => i.id !== id)
                ],
                total: state.investments.total - investment.amount
            },
            wallet: {
                ...state.wallet,
                items: [
                    ...state.wallet.items,
                    {
                        direction: "in",
                        amount: investment.amount,
                        source: `Closed investment #${investment.id} (${investment.name})`,
                        when: timestamp
                    }
                ],
                total: state.wallet.total + investment.amount,
                availableTotal: state.wallet.availableTotal + investment.amount
            }
        };
        refreshSnapshotTotals(obj);
        return obj;
    },

    InvestmentFunded: (state: IProfile, { payload: { id, amount } }) => {
        var updatedInvestment = state.investments.items.find(i => i.id === id);
        updatedInvestment.amount += amount;
        var obj: IProfile = {
            ...state,
            investments: {
                ...state.investments,
                items: [
                    ...state.investments.items
                ],
                total: state.investments.total + amount
            }
        };
        refreshSnapshotTotals(obj, 'Total')

        return obj;
    },

    InvestmentReturnsPosted: (state: IProfile, { timestamp, payload: { id, amount } }) => {
        var investment = state.investments.items.find(i => i.id == id);
        var obj: IProfile = {
            ...state,
            wallet: {
                ...state.wallet,
                items: [
                    ...state.wallet.items,
                    {
                        direction: "in",
                        amount,
                        source: `Interest from investment #${investment.id} (${investment.name})`,
                        when: timestamp
                    }
                ],
                total: state.wallet.total + amount,
                availableTotal: state.wallet.availableTotal + amount
            }
        };
        refreshSnapshotTotals(obj);
        return obj;
    },

    WalletDebited: (state: IProfile, { timestamp, payload: { actorId, reason, amount } }) => {
        var obj: IProfile = {
            ...state,
            wallet: {
                ...state.wallet,
                items: [
                    ...state.wallet.items,
                    {
                        direction: "out",
                        amount,
                        source: `${reason}`,
                        when: timestamp
                    }
                ],
                total: state.wallet.total - amount,
                availableTotal: state.wallet.availableTotal - amount
            }
        };
        refreshSnapshotTotals(obj);
        return obj;
    },

    WalletLocked: (state: IProfile, { payload: { actorId, reason } }) => {
        var obj: IProfile = {
            ...state,
            wallet: {
                ...state.wallet,
                locked: true,
                lockedReason: reason
            }
        };
        return obj;
    },

    WalletUnlocked: (state: IProfile, { payload: { actorId } }) => {
        var obj: IProfile = {
            ...state,
            wallet: {
                ...state.wallet,
                locked: false,
                lockedReason: "Not set."
            }
        };
        return obj;
    },

    WithdrawalRequested: (state: IProfile, { timestamp, payload: { id, amount } }) => {
        var obj: IProfile = {
            ...state,
            wallet: {
                ...state.wallet,
                withdrawalRequests: [
                    ...state.wallet.withdrawalRequests,
                    { id, amount, when: timestamp, status: "Pending" }
                ],
                availableTotal: state.wallet.availableTotal - amount
            }
        }
        refreshSnapshotTotals(obj, 'AvailableTotal')
        return obj;
    },

    WithdrawalScheduled: (state: IProfile, { timestamp, payload: { id, batchReference, actorId } }) => {
        var request = state.wallet.withdrawalRequests.find(r => r.id == id);
        request.status = "Scheduled";
        var obj: IProfile = {
            ...state,
            wallet: {
                ...state.wallet,
                items: [
                    ...state.wallet.items,
                    {
                        direction: "out",
                        amount: request.amount,
                        source: `Withdrawal by self (#${request.id}).`,
                        when: timestamp
                    }
                ],
                withdrawalRequests: [
                    ...state.wallet.withdrawalRequests
                ],
                total: state.wallet.total - request.amount
            }
        }
        refreshSnapshotTotals(obj, 'Total')
        return obj;
    },

    WithdrawalCancelled: (state: IProfile, { timestamp, payload: { id } }) => {
        var request = state.wallet.withdrawalRequests.find(r => r.id == id);
        request.status = "Cancelled";
        var obj: IProfile = {
            ...state,
            wallet: {
                ...state.wallet,
                withdrawalRequests: [...state.wallet.withdrawalRequests],
                availableTotal: state.wallet.availableTotal + request.amount
            }
        }
        refreshSnapshotTotals(obj, 'AvailableTotal')
        return obj;
    },

    LoanRequested: (state: IProfile, { timestamp, payload: { id, amount, type, durationInMonths } }) => {
        var obj: IProfile = {
            ...state,
            loans: {
                ...state.loans,
                requests: [
                    ...state.loans.requests,
                    { id, amount, status: "Pending", type, durationInMonths, when: timestamp, files: [] }
                ]
            }
        };
        return obj;
    },

    LoanFilesUploaded: async (state: IProfile, { timestamp, payload: { id, files } }) => {
        var loanRequest = state.loans.requests.find(r => r.id == id);

        loanRequest.files = files;

        var obj: IProfile = {
            ...state,
            loans: {
                ...state.loans
            }
        };
        return obj;
    },

    LoanDisbursementScheduled: (state: IProfile, { timestamp, payload: { id, actorId, batchReference } }) => {
        var { amount, durationInMonths, type, files } = state.loans.requests.find(l => l.id == id);
        var obj: IProfile = {
            ...state,
            loans: {
                ...state.loans,
                items: [
                    ...state.loans.items,
                    { id, amount, type, durationInMonths, balance: amount, files, when: timestamp, repayments: [], batchReference }
                ],
                requests: [
                    ...state.loans.requests.filter(l => l.id !== id)
                ],
                total: state.loans.total + amount
            }
        };
        return obj;
    },

    LoanRequestApproved: (state: IProfile, { timestamp, payload: { id, actorId } }) => {
        var request = state.loans.requests.find(l => l.id == id);
        request.status = "Approved";
        var obj: IProfile = {
            ...state,
            loans: {
                ...state.loans,
                requests: [
                    ...state.loans.requests
                ]
            }
        }
        return obj;
    },

    LoanRequestDenied: (state: IProfile, { timestamp, payload: { id, actorId, reason } }) => {
        var request = state.loans.requests.find(l => l.id == id);
        request.status = "Denied";
        request.denialReason = reason;
        var obj: IProfile = {
            ...state,
            loans: {
                ...state.loans,
                requests: [
                    ...state.loans.requests
                ]
            }
        }
        return obj;
    },

    LoanRequestPinned: (state: IProfile, { timestamp, payload: { id, actorId } }) => {
        var request = state.loans.requests.find(l => l.id == id);
        request.status = "Pinned";
        var obj: IProfile = {
            ...state,
            loans: {
                ...state.loans,
                requests: [
                    ...state.loans.requests
                ]
            }
        }
        return obj;
    },

    LoanRequestUnpinned: (state: IProfile, { timestamp, payload: { id, actorId } }) => {
        var request = state.loans.requests.find(l => l.id == id);
        request.status = "Pending";
        var obj: IProfile = {
            ...state,
            loans: {
                ...state.loans,
                requests: [
                    ...state.loans.requests
                ]
            }
        }
        return obj;
    },

    LoanRequestCancelled: (state: IProfile, { timestamp, payload: { id, actorId } }) => {
        var request = state.loans.requests.find(l => l.id == id);
        request.status = "Cancelled";
        var obj: IProfile = {
            ...state,
            loans: {
                ...state.loans,
                requests: [
                    ...state.loans.requests
                ]
            }
        }
        return obj;
    },

    LoanRepaymentMade: (state: IProfile, { timestamp, payload: { id, amount, actorId } }) => {
        var loan = state.loans.items.find(l => l.id == id);
        loan.repayments.push({ amount, postedBy: actorId, when: timestamp });
        loan.balance = loan.balance - amount;
        var obj: IProfile = {
            ...state,
            loans: {
                ...state.loans,
                total: state.loans.total - amount
            }
        }
        return obj;
    }
}