import { TenantCreated, TenantNonStaffIdGenerated, TenantRegistrationMetaKeyAdded, TenantRegistrationMetaKeyRemoved } from "../extensions/events/tenants.events";
import { ITenant, ITenantState } from "../extensions/types";

export default {
    Init: () => {
        var rv: ITenantState = {
            code: null,
            metaKeys: [],
            totalNonStaffIds: 0,
            snapshotBalances: {},
            lastNonStaffId: null,
            nonStaffs: {}
        }
        return rv;
    },
    TenantCreated: (state, { timestamp, payload }) => {
        var { code, description, actorId }: TenantCreated = payload;
        var rv: ITenant & ITenantState = {
            ...state,
            name,
            dateCreated: timestamp,
            code,
            description,
            createdBy: actorId,
            metaKeys: []
        };
        return rv;
    },
    TenantRegistrationMetaKeyAdded: (state, { payload }) => {
        var { key, description }: TenantRegistrationMetaKeyAdded = payload;
        return {
            ...state,
            metaKeys: [...state.metaKeys, { key, description }]
        };
    },
    TenantRegistrationMetaKeyRemoved: (state, { payload }) => {
        var { key }: TenantRegistrationMetaKeyRemoved = payload;
        return {
            ...state,
            metaKeys: state.metaKeys.filter(k => k.key !== key)
        };
    },
    TenantNonStaffIdGenerated: (state: ITenantState, { payload: { name, generatedId, actorId } }) => {
        var rv: ITenantState = {
            ...state,
            totalNonStaffIds: state.totalNonStaffIds + 1,
            lastNonStaffId: generatedId
        };
        rv[generatedId] = { name, actorId };
        return rv;
    },
    TenantBalanceSnapshotUploaded: (state: ITenantState, { aggregateId, payload: { snapshotType, staffs, month, year } }) => {
        var rv: ITenantState = {
            ...state,
            code: aggregateId,
            snapshotBalances: {
                ...state.snapshotBalances,
                ...{ [`${snapshotType}-${year}-${month}`]: staffs }
            }
        };
        return rv;
    }
}