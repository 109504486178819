export interface IResolveCommand {
    aggregateName: string,
    aggregateId: string,
    type: string,
    payload: any,
    jwt?: string
}

export interface IResolveUser {
    id: string,
    name: string,
    tenantId: string,
    roles: string[]
}

export interface IResolveContext {
    resolve: {
        eventstoreAdapter: {
            loadEvents(eventFilter: Partial<{ limit: number, aggregateIds: string[], eventTypes: string[], cursor: string, startTime: number, endTime: number }>)
        }
    },
    executeCommandAsync: (command: IResolveCommand) => Promise<any>,
    executeProfileCommandAsync: (type: string, payload: any, aggregateId?: string) => Promise<string>,
    scheduleCommandAsync: (timeInMillisecond: number, command: IResolveCommand) => Promise<any>,
    queryReadModelAsync: <T>(query: { modelName: string, resolverName: string, resolverArgs: { [key: string]: any }, jwt?: string }) => Promise<{ data: T }>,
    queryViewModelAsync: <T>(viewModel: string, aggregateIds: string[]) => Promise<{ data: T[] }>
    querySingleViewModelAsync: <T>(viewModel: string, aggregateId: string) => Promise<{ data: T }>
    user: IResolveUser,
    isAuthenticated: boolean,
    requiresAuthentication: () => void,
    requiresRoles: (roles: string[]) => void,
    requiresAnyRoles: (roles: string[]) => void,
    ensureTenantExistsAsync: (tenantId: string) => Promise<void>,
    rawToken: string
}
export interface ITenantState {
    code: string;
    metaKeys: [];
    totalNonStaffIds: number;
    lastNonStaffId: string;
    snapshotBalances: { [typeYearMonth: string]: { aggregateId: string, balance: string }[] };
    nonStaffs: { [id: string]: { name: string, actorId: string } }
}

export interface ITenant {
    code: string;
    name: string;
    enableBalanceSnapshot: boolean;
    administrators: string[];
    notifications: string[];
    description: string;
    registrationMeta: ITenantRegistrationMeta[],
    loanTypes: ITenantLoanTypes[]
}

export interface ITenantRegistrationMeta {
    key: string;
    description: string;
}

export interface ITenantLoanTypes {
    code: string;
    name: string;
    interestRate: number
}

export let COOPAAS: { tenants?: ITenant[] } = {};

export class Aggregates {
    static Profile: string = "Profile";
    static Tenant: string = "Tenant";
}

export interface IProfile {
    meta: { [key: string]: string },
    roles: string[],
    tenantId: string,
    fullname: string,
    staffId: string,
    reference: string,
    requiresValidation: boolean,
    validated: boolean,
    activation: {
        telegramId: string,
        phone: string
    },
    balanceSnapshots: { [key: string]: boolean },
    savings: {
        deposits: {
            [reference: string]: {
                source: string,
                amount: number,
                actorId: string
            }
        },
        deductionAmount: number,
        total: number
        activeTotalByMonth: number
        totalByMonth: { [monthYear: string]: number }
    },
    investments: {
        items: IProfileInvestment[],
        requests: IProfileInvestmentRequest[],
        total: number
    },
    loans: {
        items: IProfileLoan[],
        activeItemsByMonth: IProfileLoan[],
        itemsByMonth: { [monthYear: string]: IProfileLoan[] }
        requests: IProfileLoanRequest[],
        total: number
    },
    wallet: {
        items: WalletItem[],
        withdrawalRequests: WalletWithdrawalRequest[],
        total: number,
        availableTotal: number,
        totalWithSnapshot: number;
        avaialbleTotalWithSnapshot: number;
        locked: boolean,
        lockedReason: string
    }
}

export interface WalletWithdrawalRequest {
    id: string;
    amount: number;
    when: number;
    status: "Pending" | "Cancelled" | "Scheduled"
}

export interface WalletItem {
    amount: number,
    source: string,
    direction: "in" | "out",
    when: number
}

export interface IProfileInvestment {
    id: string;
    name: string;
    amount: number;
    source: string;
    type: string;
    when: number;
    durationInMonths: number;
}

export interface IProfileInvestmentRequest extends IProfileInvestment {
    tellerNumber: string
    status: "Rejected" | "Pending"
    rejectionReason?: string
}

export interface ILoan {
    id: string;
    amount: number;
    type: string;
    name?: string;
    files?: {
        id: string;
        description: string,
        type: string,
        downloadUrl: string
    }[]
    durationInMonths: number;
    when: number
}

export interface IProfileLoan extends ILoan {
    balance: number;
    batchReference: string;
    repayments: IProfileLoanRepayment[];
}

export interface IProfileLoanRequest extends ILoan {
    status: "Pending" | "Denied" | "Pinned" | "Approved" | "Cancelled";
    denialReason?: string;
}

export interface IProfileLoanRepayment {
    amount: number,
    when: number,
    postedBy: string
}